import React from "react";
import gnome from '../images/DD_logo_gnome.png';
import logo from '../images/DD_logo.png';

const IndexPage = () => (
  <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <img style={{ width: '35%' }} src={gnome} />
    <img style={{ width: '35%' }} src={logo} />
  </div>
)

export default IndexPage
